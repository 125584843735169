import React, { useState, useEffect } from "react"

const useScrollTracking = items => {
  const [activeItem, setActiveItem] = useState(items[0])

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY
      const itemPositions = items.map((item, index) => {
        const element = document.getElementById(`item${index + 1}`)
        return {
          item,
          position: element ? element.offsetTop : 0,
        }
      })

      const currentItem = itemPositions.find(
        (itemInfo, index) =>
          scrollY >= itemInfo.position &&
          (index === itemPositions.length - 1 ||
            scrollY < itemPositions[index + 1].position)
      )

      setActiveItem(currentItem ? currentItem.item : items[items.length - 1])
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [items])

  return activeItem
}

export default useScrollTracking
