import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import SEO from "../components/seo"
import Header from "../components/Navbar/Navbar.js"
import Footer from "../components/Footer/Footer.js"
import Scroll from "../components/ScrollTop/Scroll"
import CaseStudiesDetail from "../components/CaseStudiesDetail"
import { scrollTop } from "../components/layout"

const CaseStudiesDetailPage = ({ pageContext }) => {
  const { t } = useTranslation()
  const { postTitle } = pageContext

  return (
    <>
      <SEO title={t(`${postTitle}`)} />
      <Header />
      <CaseStudiesDetail {...pageContext} />
      <Scroll showBelow={scrollTop} />
      <Footer />
    </>
  )
}

export default CaseStudiesDetailPage
