import React from "react"
import _ from "lodash"
import Slider from "react-slick"
import { navigate } from "gatsby"
import MarkdownIt from "markdown-it"
import { useTranslation } from "gatsby-plugin-react-i18next"

import {
  carouselSettings,
  caseStudiesList,
} from "../../data/case-studies-setting"
import useScrollTracking from "./hook/useScrollTracking"

import "./index.scss"

const useImageSelection = (activeItem, itemImages) =>
  activeItem ? itemImages[activeItem] : null

const CaseStudiesDetail = ({
  postBanner,
  challengeImg,
  solutionImg,
  impactImg,
  postLogo,
  postIntro,
  postIntroParagraph,
  postContent,
  altTextPostLogo,
  altTextPostBanner,
  altTextChallengeImg,
  altTextSolutionImg,
  altTextImpactImg,
}) => {
  const md = new MarkdownIt()
  const { t } = useTranslation()

  const items = ["Item 1", "Item 2", "Item 3", "Item 4"]

  const itemImages = {
    "Item 1": `${process.env.GATSBY_CDN_CONFIG + postBanner}`,
    "Item 2": `${process.env.GATSBY_CDN_CONFIG + challengeImg}`,
    "Item 3": `${process.env.GATSBY_CDN_CONFIG + solutionImg}`,
    "Item 4": `${process.env.GATSBY_CDN_CONFIG + impactImg}`,
  }

  const activeItem = useScrollTracking(items)
  const selectedImage = useImageSelection(activeItem, itemImages)

  const handleRedirect = url => {
    navigate(url)
  }

  const getAltText = () => {
    switch(activeItem) {
      case "Item 1":
        return altTextPostBanner;
      case "Item 2":
        return altTextChallengeImg;
      case "Item 3":
        return altTextSolutionImg;
      case "Item 4":
        return altTextImpactImg;
    }
  }

  return (
    <>
      <div className="case-study-detail margin-top">
        <div className="img-container img-container__pc">
          {selectedImage && <img src={selectedImage} alt={getAltText() || ""} />}
        </div>
        <div className="case-study-detail__text">
          <div id={`item1`} className="post-content first-item">
            <div>
              <img
                className="img-fluid img-container__mobile"
                src={_.get(itemImages, "Item 1")}
                alt={altTextPostBanner || ""}
              />
            </div>
            <div>
              <img
                className="img-fluid"
                src={process.env.GATSBY_CDN_CONFIG + postLogo}
                alt={altTextPostLogo || ""}
              />
            </div>
            <strong>{postIntro}</strong>
            <div
              dangerouslySetInnerHTML={{
                __html: md.render(postIntroParagraph),
              }}
            />
          </div>
          {postContent &&
            postContent.map((item, index) => {
              let stepIcon
              let stepImage
              let altText = ""
              switch (true) {
                case _.includes(item, "## Challenge"):
                  stepIcon = `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/challenges-icon.png`
                  altText = "Case Study - challenges icon"
                  stepImage = _.get(itemImages, "Item 2")
                  break

                case _.includes(item, "## Solution"):
                  stepIcon = `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/solutions-icon.png`
                  altText = "Case Study - Solutions icon"
                  stepImage = _.get(itemImages, "Item 3")
                  break

                case _.includes(item, "## Impact"):
                  stepIcon = `${process.env.GATSBY_CDN_CONFIG}/assets/image/case-studies/impact-icon.png`
                  altText = "Case Study - Impact icon"
                  stepImage = _.get(itemImages, "Item 4")
                  break

                default:
                  break
              }

              return (
                <div
                  key={index}
                  id={`item${index + 2}`}
                  className="post-content"
                >
                  {stepImage && (
                    <div>
                      <img
                        className="img-fluid img-container__mobile"
                        src={stepImage}
                        alt={getAltText() || ""}
                      />
                    </div>
                  )}
                  <div>
                    {stepIcon && <img className="icon" src={stepIcon} alt={altText}/>}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: md.render(item),
                    }}
                  />
                </div>
              )
            })}
        </div>
      </div>
      <div className="case-study-detail__other">
        <div className="slider-wrapper">
          <div className="client-list__header">
            <h1>{t("READ MORE ABOUT OUR CASE STUDIES")}</h1>
            <div className="line-break"></div>
          </div>
          <Slider {...carouselSettings}>
            {caseStudiesList.map(
              ({ logo, description, imgLink, url, altText }, index) => {
                return (
                  <div>
                    <div
                      key={index}
                      style={{ backgroundImage: `url(${imgLink})` }}
                      className="card-wrapper"
                      onClick={() => handleRedirect(url)}
                    >
                      <div className="item-overlay"></div>
                      <div className="thumbnail">
                        <img src={logo} alt={altText}/>
                        <a className="description">{description}</a>
                      </div>
                    </div>
                  </div>
                )
              }
            )}
          </Slider>
        </div>
      </div>
    </>
  )
}

export default CaseStudiesDetail
